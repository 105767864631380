import axios from 'axios';

const apiUrl = process.env.REACT_APP_APIURL;

export const FETCH_ALL_USERS = 'FETCH_ALL_USERS';

export const fetchAllUsers = (data) => ({
  type: FETCH_ALL_USERS,
  payload: data
});


export const getAllUsers = (filters) => {
  console.log(filters)
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/user/get-all-history`, {
        params: {
          date: filters?.date,
          name: filters?.name,
        }
      });
      if (response.status === 200) {
        console.log('api res: ', response.data);
        dispatch(fetchAllUsers(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
      } else {
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const addUser = (inputValues) => {
  console.log('input value: ', inputValues);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/add-user`, { inputValues });

      if (response.status === 200) {
        console.log('res: ', response.data);
        alert('User Added')
        return response.data;
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error?.response?.data?.errorMsg);
        alert('Add User Fail')
      } else {
        // Handle other errors
        console.log(error.message);
        alert('Add User Fail')
      }
      return { error: error.message };
    }
  };
};