import { FETCH_ALL_USERS } from '../actions/userAction';

const initialState = {
    users: []
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_USERS:
            return {
                ...state,
                users: action.payload.result
            };

        default:
            return state;
    }
};

export default userReducer;