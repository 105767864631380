import React from 'react';
import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import Users from '../screens/users';

const router = createBrowserRouter(
    createRoutesFromElements(
      <>
          <Route path="/tcp" element={<Users />} />
      </>
    )
  );
  
  export default router;