import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown, faFileExcel, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { getAllUsers, addUser } from '../actions/userAction';
import DatePicker from './datepicker';
import * as XLSX from 'xlsx';
import Modal from 'react-modal';

const Users = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'descending' });
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [name, setName] = useState('');
  const [filters, setFilters] = useState({ date: new Date().toISOString() });
  const [searchInput, setSearchInput] = useState(filters);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    company: 'The Corner Playhouse',
    firstName: '',
    lastName: '',
    email: '',
    countryCode: '+65',
    contactNumber: ''
  });

  const users = useSelector((state) => state.users.users);


  useEffect(() => {
    dispatch(getAllUsers(filters));
  }, [dispatch, filters]);

  const handleDateSelect = (date) => {
    console.log(date); // Check the date value here
    setFilters({ ...filters, date });
  };

  const onChangeTextField = (e) => {
    const { name, value } = e.target;
    //setSearchInput({ ...searchInput, [name]: value });
    setFilters({ ...filters, [name]: value });
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };


  const sortedUsers = useMemo(() => {
    if (!users) return [];

    let sortableUsers = [...users];
    sortableUsers.sort((a, b) => {
      const keyA = sortConfig.key === 'firstName' || sortConfig.key === 'lastName' ? a.user[sortConfig.key] : a[sortConfig.key];
      const keyB = sortConfig.key === 'firstName' || sortConfig.key === 'lastName' ? b.user[sortConfig.key] : b[sortConfig.key];

      if (keyA < keyB) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (keyA > keyB) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    return sortableUsers;
  }, [users, sortConfig]);


  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return faSort;
    }
    return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
  };

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = sortedUsers.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(sortedUsers.length / entriesPerPage);

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const downloadExcel = () => {
    try {
      const formattedUsers = users.map((user) => ({
        'First Name': user?.user?.firstName || '',
        'Last Name': user?.user?.lastName || '',
        'Access Date': !Number.isNaN(new Date(user?.createdAt).getTime())
          ? format(new Date(user?.createdAt), 'dd-MM-yyyy HH:mm')
          : 'Invalid Date'
      }));

      const worksheet = XLSX.utils.json_to_sheet(formattedUsers);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');

      // Adjust column width
      const maxLength = {};
      formattedUsers.forEach((row) => {
        Object.entries(row).forEach(([key, value]) => {
          const length = String(value).length;
          maxLength[key] = Math.max(maxLength[key] || 0, length);
        });
      });
      const colWidths = Object.values(maxLength).map((length) => ({ wch: length + 2 })); // +2 for padding
      worksheet['!cols'] = colWidths;

      const now = new Date();
      const formattedDate = format(now, 'yyyy-MM-dd_HH-mm');
      const filename = `users_${formattedDate}.xlsx`;

      XLSX.writeFile(workbook, filename);
      alert('Excel Download Successful')
    } catch (error) {
      alert('Error Downloading Excel')
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addUser(formData)); // Dispatch the addUser action
    closeModal();
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '400px', // Adjust width as needed
      padding: '20px'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)'
    }
  };

  return (
    <div className="m-4 md:m-10"> {/* Adjust margin for different screen sizes */}
      <div className="flex flex-col justify-center p-5 gap-y-5">
        <div className="flex flex-col md:flex-row md:items-center justify-between space-y-4 md:space-y-0 md:space-x-4">
          <div className="flex flex-col md:flex-row md:items-center space-y-4 md:space-y-0 md:space-x-4">
            <div className="flex flex-col md:flex-row md:items-center">
              <div className="text-sm font-nunito font-bold leading-normal text-oppty-grey-16">Search By Name</div>
              <input
                type="text"
                id="name"
                name="name"
                onChange={onChangeTextField}
                value={filters?.name || ''}
                className="py-2 px-4 leading-tight border focus:outline rounded-md border-gray-300 mt-2 md:mt-0 md:ml-4"
              />
            </div>
            <div className="flex flex-col md:flex-row md:items-center">
              <div className="text-sm font-nunito font-bold leading-normal text-oppty-grey-16">Search By Date</div>
              <DatePicker value={filters?.date} onChange={handleDateSelect} className="py-2 px-4 leading-tight border focus:outline-none rounded-md border-gray-300 mt-2 md:mt-0 md:ml-4" />
            </div>
          </div>
          <button onClick={openModal} className="flex items-center justify-center bg-blue-500 text-white px-4 py-2 rounded-md">
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            <span>Add User</span>
          </button>
        </div>
        <div className="flex justify-between items-center">
          <div className="text-lg font-nunito font-bold leading-normal text-black">
            {users?.length > 0
              ? users?.length === 1
                ? 'Showing 1 Entry'
                : `Showing ${users?.length} Entries`
              : 'No entries available'}
          </div>
          <div className="flex items-center font-semibold">
            <label htmlFor="entriesPerPage" className="mr-2">
              Show
            </label>
            <select
              id="entriesPerPage"
              value={entriesPerPage}
              onChange={handleEntriesPerPageChange}
              className="border border-gray-300 rounded-md p-1"
            >
              {[5, 10, 20, 50].map((num) => (
                <option key={num} value={num}>
                  {num}
                </option>
              ))}
            </select>
            <span className="ml-2">Entries</span>
            <button onClick={downloadExcel} className="ml-4">
              <FontAwesomeIcon icon={faFileExcel} size="2x" color="green" />
            </button>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="table-auto bg-white rounded-2xl w-full mt-4">
            <thead>
              <tr className="h-20 border-b-[1px]">
                <th onClick={() => handleSort('firstName')} className="cursor-pointer">
                  First Name
                  <FontAwesomeIcon icon={getSortIcon('firstName')} size="sm" className="ml-1" />
                </th>
                <th onClick={() => handleSort('lastName')} className="cursor-pointer">
                  Last Name <FontAwesomeIcon icon={getSortIcon('lastName')} size="sm" className="ml-1" />
                </th>
                <th onClick={() => handleSort('createdAt')} className="cursor-pointer">
                  Access Date <FontAwesomeIcon icon={getSortIcon('createdAt')} size="sm" className="ml-1" />
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentEntries?.map((user, index) => {
                const formattedCreatedAtDate = !Number.isNaN(new Date(user?.createdAt).getTime())
                  ? format(new Date(user?.createdAt), 'dd-MM-yyyy HH:mm')
                  : 'Invalid Date';
                return (
                  <tr className={`h-20`} key={index}>
                    <td className="h-20 text-center">{user?.user?.firstName}</td>
                    <td className="h-20 text-center">{user?.user?.lastName}</td>
                    <td className="h-20 text-center">{formattedCreatedAtDate}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="flex flex-col md:flex-row justify-between items-center mt-4 text-sm">
          <span>
            Showing {indexOfFirstEntry + 1} to {Math.min(indexOfLastEntry, sortedUsers.length)} of {sortedUsers.length}{' '}
            entries
          </span>
          <div className="flex items-center mt-2 md:mt-0">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="px-4 py-2 bg-gray-300 rounded-md"
            >
              Previous
            </button>
            <ul className="flex list-none mx-2">
              {pageNumbers.map((number) => (
                <li
                  key={number}
                  className={`mx-1 px-3 py-1 border rounded-md ${currentPage === number ? 'bg-gray-300' : ''}`}
                >
                  <button onClick={() => setCurrentPage(number)}>{number}</button>
                </li>
              ))}
            </ul>
            <button
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
              className="px-4 py-2 bg-gray-300 rounded-md"
            >
              Next
            </button>
          </div>
        </div>
      </div>

      {/* Add User Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Add User"
        style={customStyles}
      >
        <h2 className="text-lg font-bold mb-4">Add User</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block mb-2">Company</label>
            <input
              type="text"
              name="company"
              value={formData.company}
              onChange={handleFormChange}
              className="w-full p-2 border rounded bg-gray-200"
              disabled
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">First Name</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleFormChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Last Name</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleFormChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleFormChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Country Code</label>
            <input
              type="text"
              name="countryCode"
              value={formData.countryCode}
              onChange={handleFormChange}
              className="w-full p-2 border rounded bg-gray-200"
              disabled
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Contact Number</label>
            <input
              type="text"
              name="contactNumber"
              value={formData.contactNumber}
              onChange={handleFormChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={closeModal}
              className="bg-gray-300 px-4 py-2 rounded"
            >
              Cancel
            </button>
            <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
              Submit
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default Users;
