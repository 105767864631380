import flatpickr from 'flatpickr';
import { useEffect, useRef } from 'react';
import { format } from 'date-fns';

const DatePicker = ({ value, onChange }) => {
  const datePickerRef = useRef(null);

  useEffect(() => {
    const defaultDate = value ? new Date(value) : new Date();
    const fp = flatpickr(datePickerRef.current, {
      mode: 'single',
      static: true,
      monthSelectorType: 'static',
      dateFormat: 'd/m/Y',
      prevArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
      defaultDate: defaultDate,
      onChange: (selectedDates) => {
        if (selectedDates.length > 0) {
          const formattedDate = format(selectedDates[0], 'yyyy-MM-dd HH:mm:ss');
          onChange(formattedDate);
        }
      }
    });

    return () => {
      fp.destroy();
    };
  }, [onChange, value]);

  return (
    <div className="relative flex items-center">
      <input
        ref={datePickerRef}
        className="form-datepicker py-2 px-4 leading-tight border focus:outline-none rounded-md border-gray-300 w-full pr-10"
        placeholder="dd/mm/yyyy"
        value={value ? format(new Date(value), 'dd/MM/yyyy') : format(new Date(), 'dd/MM/yyyy')}
        readOnly
      />
    </div>
  );
};

export default DatePicker;
